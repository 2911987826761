import { GoogleTagManager } from '../../integrations/google_tag_manager'
import { Amplitude } from '../../integrations/amplitude'
import { VWO } from '../../integrations/vwo'
import {
  sessionStorageAvailable,
  sessionStorageGetItem,
  sessionStorageRemoveItem,
} from '../../vendor/storage_utilities'

export class ThankYouPage {
  constructor() {
    this.el = document.querySelector('[data-context="thank-you-page"]')
    if (!this.el) return

    this.initAnalytics()
    this.trackKeyButtonClicks()
    this.trackFineCrossSellingButtonClicks()
  }

  initAnalytics() {
    // events which will be pushed on each refresh
    this.pushThankYouPageTrackingEvent()
    Amplitude.setUserId(ANALYTICS_DATA.user_id)

    // events which will be pushed once
    if (ANALYTICS_DATA.freshly_cancelled) {
      if (ANALYTICS_DATA.new_user) {
        GoogleTagManager.pushDataLayerEvent('accountCreated', {
          userRegisteredSince: new Date().toISOString().split('T')[0],
          userActiveContractsCount: 0,
          userFirstTimeCustomer: true,
          userLoginIn: false,
          userId: ANALYTICS_DATA.user_id,
          userLocation: ANALYTICS_DATA.user_location,
          customerType: 'new',
        })
      }

      GoogleTagManager.pushDataLayerEvent('cancellationOrdered', {
        cancellationType: 'external',
        cancellationCategoryId: ANALYTICS_DATA.category_id,
        cancellationCategory: ANALYTICS_DATA.category,
        cancellationVendor: ANALYTICS_DATA.vendor_name,
        cancellationBrand: ANALYTICS_DATA.vendor_brand,
        availableProducts: ANALYTICS_DATA.available_products,
        cancellationId: ANALYTICS_DATA.cancellation_id,
        cancellationReason: ANALYTICS_DATA.reasons,
        cancellationAmount: ANALYTICS_DATA.amount,
        cancellationProduct: ANALYTICS_DATA.products,
        cancellationTotalCost: ANALYTICS_DATA.total_cost,
        chosenProducts: ANALYTICS_DATA.chosen_products,
        userLoginIn: false,
        userId: ANALYTICS_DATA.user_id,
        userLocation: ANALYTICS_DATA.user_location,
        customerType: ANALYTICS_DATA.new_user ? 'new' : 'returning',
        vendorId: ANALYTICS_DATA.vendor_id,
        discountApplied: ANALYTICS_DATA.discount_added,
      })

      let chosenProductsNames = ANALYTICS_DATA.chosen_products.map((product) => product.chosenProductName)

      let amplitudeData = {
        slug: ANALYTICS_DATA.checkout_path + '?vendorName=' + ANALYTICS_DATA.vendor_brand,
        contractType: ANALYTICS_DATA.category,
        brandName: ANALYTICS_DATA.vendor_brand,
        chosenProducts: chosenProductsNames,
        cancelReasons: ANALYTICS_DATA.reasons,
        cancellationLocation: 'external',
        totalCost: (ANALYTICS_DATA.total_cost / 100).toPrecision(3),
        totalPrice: ANALYTICS_DATA.amount,
        userId: ANALYTICS_DATA.user_id,
        defaultDeliveryMethod: ANALYTICS_DATA.default_product,
        customerType: ANALYTICS_DATA.new_user ? 'new' : 'returning',
        discountApplied: ANALYTICS_DATA.discount_added,
        nameOfCodeApplied: ANALYTICS_DATA.voucher_code,
      }

      if (amplitudeData.chosenProducts.includes('money_back_service')) {
        Amplitude.logEvent('CS Money-Back Service Ordered', amplitudeData)
      }

      Amplitude.logEvent('CS Transaction Cancellation Ordered', amplitudeData)

      ANALYTICS_DATA.chosen_products.forEach((product) => {
        Amplitude.logRevenue(product.chosenProductName, 1, product.chosenProductPrice, amplitudeData)
      })

      if (sessionStorageAvailable() && sessionStorageGetItem('data_deletion') === 'consent_given') {
        Amplitude.logEvent('CS Thank You Page Data Deletion Survey Submission', amplitudeData)
        sessionStorageRemoveItem('data_deletion')
      }

      this.pushVWOTrackingEvent()
    }
  }

  pushVWOTrackingEvent() {
    VWO.triggerRevenue(ANALYTICS_DATA.amount)
    if (ANALYTICS_DATA.includes_security_package) VWO.triggerEvent(245)

    const packagesWithoutAdditionalPackage = ANALYTICS_DATA.available_products
      .filter((product) => product.availableProductName !== 'termination_security_service')
      .sort((a, b) => a.availableProductPrice - b.availableProductPrice)
    const userSelectedPackagesWithoutAdditionalPackage = ANALYTICS_DATA.products.filter(
      (product) => product !== 'termination_security_service'
    )[0]
    const priceIndexOfSelectedPackage = packagesWithoutAdditionalPackage.findIndex(
      (p) => p.availableProductName === userSelectedPackagesWithoutAdditionalPackage
    )

    if (packagesWithoutAdditionalPackage.length < 3) {
      if (packagesWithoutAdditionalPackage === 0) return VWO.triggerEvent(237)
      return VWO.triggerEvent(239)
    }

    switch (priceIndexOfSelectedPackage) {
      case 0:
        VWO.triggerEvent(237)
        break
      case 1:
        VWO.triggerEvent(238)
        break
      case 2:
        VWO.triggerEvent(239)
        break
    }
  }

  pushThankYouPageTrackingEvent() {
    VWO.triggerEvent(200)
    const analytics_data = {
      userId: ANALYTICS_DATA.user_id,
      freshlyCancelled: ANALYTICS_DATA.freshly_cancelled ? 'yes' : 'no',
      cancellationID: ANALYTICS_DATA.cancellation_id,
      subjectID: ANALYTICS_DATA.category_id,
      cancellationCategory: ANALYTICS_DATA.category,
      brandName: ANALYTICS_DATA.brand_name,
      responseTime: ANALYTICS_DATA.response_time,
      confirmationResponseType: ANALYTICS_DATA.confirmation_response_type,
      shippingConfirmationGenerated: ANALYTICS_DATA.shipping_confirmation_generated,
      discountApplied: ANALYTICS_DATA.discount_added,
      userReferralCode: ANALYTICS_DATA.user_referral_code,
    }

    GoogleTagManager.pushDataLayerEvent('ThankYouPage_displayed', analytics_data)
    Amplitude.logEvent('CS Thank You Page Pageview', analytics_data)
  }

  trackKeyButtonClicks() {
    const cancelAnotherContractButton = this.el.querySelector('[data-context="cancel-another-contract"]')
    const downloadButton = this.el.querySelector('[data-context="download-button"]')

    if (cancelAnotherContractButton) {
      cancelAnotherContractButton.addEventListener('click', () => {
        Amplitude.logEvent('CS Thank You Page Cancel New Contract Button Clicked', {
          cancellationID: ANALYTICS_DATA.cancellation_id,
        })
      })
    }

    if (downloadButton) {
      downloadButton.addEventListener('click', () => {
        Amplitude.logEvent('CS Thank You Page Shipping Confirmation Downloaded', {
          cancellationID: ANALYTICS_DATA.cancellation_id,
        })
      })
    }
  }

  trackFineCrossSellingButtonClicks() {
    const fineFlightButton = this.el.querySelector('[data-context="fine-flight-compensation"]')
    const fineGamblingButton = this.el.querySelector('[data-context="fine-gambling-loss"]')
    const fineContractButton = this.el.querySelector('[data-context="fine-contract-termination"]')

    if (fineFlightButton) {
      fineFlightButton.addEventListener('click', () => {
        Amplitude.logEvent('Fine flight compensation button clicked', {
          cancellationID: ANALYTICS_DATA.cancellation_id,
        })
      })
    }

    if (fineGamblingButton) {
      fineGamblingButton.addEventListener('click', () => {
        Amplitude.logEvent('Fine gambling loss button clicked', {
          cancellationID: ANALYTICS_DATA.cancellation_id,
        })
      })
    }

    if (fineContractButton) {
      fineContractButton.addEventListener('click', () => {
        Amplitude.logEvent('Fine contract termination button clicked', {
          cancellationID: ANALYTICS_DATA.cancellation_id,
        })
      })
    }
  }
}
